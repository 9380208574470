import * as React from "react"
import Layout from "../../components/layout"
import Star from "../../components/star"
import '../../style.css'

const Sticker = () => {

    const info={
        title:'The Swift Sticker',
        image:'sat.png',
        page:'sticker',
        description:'A spectacular image of the Swift satellite and depiction of the mission.',
        internalText:[
          "This sticker is great for teachers, students, or anybody in the general public.", 
          "Features Swift artwork as well as text on the back of the sticker:",
          "Swift is an international mission in the NASA Explorer program to study Gamma-Ray Bursts.",
          "Gamma-ray bursts are huge explosions located in distant galaxies, but their cause remains one of the greatest mysteries in astronomy. Swift is designed to detect the bursts and autonomously point sensitive telescopes at their position to observe the fading optical, ultraviolet, and X-ray afterglows. The spacecraft is named 'Swift' because it can very rapidly -- usually in under a minute -- swing around and aim its telescopes at a gamma-ray burst. The scientific goals are to determine the origin of the bursts and to use bursts to probe the distant universe.",
          "Swift is a NASA mission that was built and will be operated by an international collaboration. Countries with major contributions are the US, Italy and the UK.",
        ], 
        pdf:{
          pdf:'swiftSticker.jpg',
          accessible:null
        }
    }

  return (
    <Layout>
        <div>
          <h1>{info.title}</h1>
          <Star/>
          <p>{info.internalText}</p>
          {info.pdf.accessible?
          <p>Check out the <a href={`/resourceLinks/${info.pdf.pdf}`} target='_blank' rel="noreferrer">PDF</a> and <a href={`/resourceLinks/${info.pdf.accessible}`} target='_blank' rel="noreferrer">Disability accessible PDF</a></p>
          :
          <p>Check out the <a href={`/resourceLinks/${info.pdf.pdf}`} target='_blank' rel="noreferrer">PDF</a></p>
          }
        </div>
    </Layout>
  )
}

export default Sticker